import React, { useEffect, useRef } from "react";
import {
   BlogCard,
   Button,
   FeatureInvestor,
   InfoCard,
   PartnerSlide,
   ProgramCard,
   RingBullet,
   Testimony,
   TextField,
   TitledSection,
   WithLayout
} from "../../components";
import program1 from "../../assets/images/programImg1.png";
import program2 from "../../assets/images/programImg2.png";
import program3 from "../../assets/images/programImg3.png";
import deco from "../../assets/images/worksDeco.svg";
import incorporated from "../../assets/images/startupIncoporated.svg";
import minimum from "../../assets/images/minFounder.svg";
import active from "../../assets/images/activeCustomer.svg";
import prototype from "../../assets/images/workPrototype.svg";
import incubated from "../../assets/images/incubateIdea.svg";
import globalLocal from "../../assets/images/globalLocalMentor.svg";
import networkPartner from "../../assets/images/networkPartners.svg";
import courseAccess from "../../assets/images/courseAccess.svg";
import founderCom from "../../assets/images/founderCommunity.svg";
import accessInvestor from "../../assets/images/accessInvestors.svg";
import remoteProgram from "../../assets/images/remoteProgramm.svg";
import legal from "../../assets/images/legalAssistance.svg";
import brand from "../../assets/images/Brand&PR.svg";
import ideasToReality from "../../assets/images/ideasToReality.png";
import startupInvestment from "../../assets/images/startupInvestment.png";
import productRoadMap from "../../assets/images/productRoadmap.png";
import landingVideo from "../../assets/images/bgVideo.mp4";
import "./home.css";

export const Home = WithLayout(() => {
   const whyJoin = [
      {
         icon: globalLocal,
         title: "Access to global and local mentors",
         desc: "You will be matched to a mix of local and global successful mentors who have experience and expertise closely matched to your field of business and are passionate about supporting your journey. We deliberately seek out mentors who understand the peculiarity of the African terrain and were possibly entrepreneurs themselves. A great opportunity to leverage their insights, networks and reputation."
      },
      {
         icon: networkPartner,
         title: "Access to network partners",
         desc: "We are opportuned to have a network of corporate partners who share in our mission to provide value and give you a headstart with a bouquet of resources, tools, software and platforms to accelerate your business idea."
      },
      {
         icon: courseAccess,
         title: "Access to entrepreneurial courses",
         desc: "The Knight Ventures program offers a curriculum focused on the business realities of scaling a business and housed within a supportive community of enterprise-oriented founders and mentors.  The structured curriculum anchors peer group conversations  focused on product viability, market traction, customer acquisition and fundraising, supplemented with focused brainstorming on topics founders identify as particularly relevant to them."
      },
      {
         icon: founderCom,
         title: "Access to founders community",
         desc: "Thrive and be incentivised as you network and get constructive feedback in a structured manner from other peer cohort members and the founder community."
      },
      {
         icon: accessInvestor,
         title: "Access to investors",
         desc: "Our network of investors are matched by interest in your business and provided upfront information to assist in quick decision making. Participants will have to reach a level of interest for the different funding stages of investment. Our investors are selected for their commitment to ethical investment practises and consciousness to build profitable legacies"
      },
      {
         icon: remoteProgram,
         title: "12 weeks remote programme",
         desc: "Founders participate in the accelerator from wherever they choose to work virtually and build their companies. All programming and events are designed for the best possible virtual experience. Knight’s physical hubs are in Lagos Nigeria. Networking activities and other in-person events will take place on both platforms in those areas."
      },
      {
         icon: legal,
         title: "Legal & compliance assistance",
         desc: "We provide our cohort members legal and compliance assistance as part of our proactive due diligence measures to ensure smooth discussions at potential partner and investment negotiations."
      },
      {
         icon: brand,
         title: "Brand & PR Networking",
         desc: "Visibility is key to your business scale aspirations and we will support this through appropriate negotiations for publicity of your brand and offerings"
      }
   ];

   const videoRef = useRef(null);

   useEffect(() => {
      videoRef.current.play();
   }, []);

   return (
      <div>
         <section className="position-relative">
            <video
               autoplay={true}
               loop
               id="myVideo"
               style={{
                  width: "100%",
                  minHeight: "600px",
                  maxHeight: "600px",
                  backgroundColor: "#000",
                  objectFit: "cover"
               }}
               ref={videoRef}
            >
               <source src={landingVideo} type="video/mp4" />
            </video>

            <div
               className={`row mx-0 banner w-100`}
               style={{
                  Height: 600,
                  position: "absolute",
                  zIndex: 10,
                  top: 0,
                  backgroundColor: "#00000050"
               }}
            >
               <section className="col-xl-7 col-lg-10 d-flex align-items-center">
                  <div>
                     <div className="banner-main">
                        <h1>
                           Investing in people Building businesses across <span>Africa</span>
                        </h1>
                     </div>
                     <p className="banner-sub">
                        Knight Ventures is a Pan Africa Digital Accelerator providing a platform to
                        connect startups, mentors and Investors. We have a mission to develop 100
                        high-growth tech enabled startups to scale and success.
                     </p>
                     {/* <Button label="Get Started" /> */}
                     <a href="https://knight.ventures/waitlist.html">Get Started</a>
                  </div>
               </section>
            </div>
         </section>

         <TitledSection title="our programs" subtitle="Boost up with our hands-on program">
            <div className="row mt-5">
               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     image={program1}
                     footer="The Incubation Program"
                     desc="The incubation program is to guide the budding entrepreneur in doing research on the market & industry"
                     fullDesc="The incubation program is to guide the budding entrepreneur in doing research on market & industry for detailed understanding of the competitive landscape, industry structure, growth outlook, and other key factors. We help in identifying the problem to be solved by validating the business idea. Our program helps in building an investable product, finding product-market fit, product design and prototyping, how to get early customers and grow pitch deck preparation along with mock pitch session to communicate their mission through storytelling. The duration of program is 6-12 months."
                  />
               </section>

               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     image={program2}
                     footer="Acceleration Program"
                     desc="Our Acceleration program is targeted at founders, who desire to scale their businesses and attract investment."
                     fullDesc="Our Acceleration program is targeted at founders, who desire to scale their businesses and attract investment. The program runs virtually over a 3-month period with programming and events optimised for WAT time zones, though founders may participate from anywhere in the world. We offer a structured curriculum of strategy development and execution for deepened market traction and the required supporting operational and managerial capacity. Founders will be subjected to rigorous reviews through the duality of matched industry mentors and through peer cohort group conversations to test revenue models, scalability and sustainability. Founders businesses will be appraised and supported using due diligence measures towards determining their level of Investment readiness particularly in areas governance, financial health and returns, brand architecture and partnerships.
                     "
                  />
               </section>

               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     className="investor-connect-card"
                     image={program3}
                     footer="Investor Connect
                     Seed, Pre-Series A, Series A"
                     desc="The investor connect is a network of investors with an African affinity"
                     fullDesc="The investor connect is a network of investors with an African affinity from Angels to Capital Ventures who leverage the platform in accessing quality deal flows of due-diligenced high growth enterprises. The funding gaps to be filled are at early stages from pre-seed to Series A. Deal flows will be expertly matched to interest and expertise in a seamless process allowing for more familiarity with options presented. Critical value from this platform is the strategic partnerships and network of home-grown investors who will derive deepened capacity and more regional investment opportunities in scalable solutions driven by innovative founders"
                  />
               </section>
            </div>
         </TitledSection>

         <TitledSection
            title="How It Works"
            subtitle="Our application process is easy to complete"
            titlePosition="center"
            bg="#F9F9FB"
            className="px-3"
         >
            <section
               className="d-flex align-items-center justify-content-center flex-wrap"
               style={{ paddingTop: "4rem", columnGap: 50, rowGap: 18 }}
            >
               <section className="works-section">
                  <div className="d-flex align-items-center mb-5">
                     <RingBullet />
                     <div className="ml-4">
                        <p className="works-title">Registration</p>
                        <p className="mb-0 works-desc">
                           The first step towards your acceleration is to sign up with your business
                           idea to signify your interest for more information on details of our
                           program and curriculum.
                        </p>
                     </div>
                  </div>

                  <div className="d-flex align-items-center mb-5">
                     <RingBullet color="#2E3192" border="#979797" />
                     <div className="ml-4">
                        <p className="works-title">Apply for the program</p>
                        <p className="mb-0 works-desc">
                           Industry wide Founders|Enterprises particularly Women led/team
                           enterprises are highly encouraged to apply. You will have developed your
                           idea ideally up to a Minimum Viable Product (MVP) level with evidence of
                           commercial traction and technology enhancement. Your level of business
                           will determine the appropriate program for you. Knight Ventures has
                           rolling application deadlines.
                        </p>
                     </div>
                  </div>
               </section>
               <section style={{ flexBasis: "20%" }}>
                  <img src={deco} alt="deco" className="w-100" />
               </section>

               <section className="works-section">
                  <div className="d-flex align-items-center mb-5">
                     <RingBullet color="#2E3192" border="#979797" />
                     <div className="ml-4">
                        <p className="works-title">Connect with Investors</p>
                        <p className="mb-0 works-desc">
                           Successful applicants into the program will be onboarded and supported
                           towards an investment readiness state for the duration of the program
                           culminating in a demo pitching session to a matched community of
                           investors.
                        </p>
                     </div>
                  </div>
                  <div className="d-flex align-items-center mb-5">
                     <RingBullet />
                     <div className="ml-4">
                        <p className="works-title">Raise Funds</p>
                        <p className="mb-0 works-desc">
                           Success at the demo day leads to your startup getting funded to the right
                           level of capital in order to maximise options down the line.
                        </p>
                     </div>
                  </div>
               </section>
            </section>
         </TitledSection>

         <TitledSection
            title="Selection criteria"
            subtitle={
               <>
                  Our selection criteria
                  <br /> is pretty Simple
               </>
            }
         >
            <section className="row mt-5">
               <div className="col-lg-4 d-flex align-items-center justify-content-center mb-5">
                  <InfoCard
                     title="Startup Incorporated in Africa or Abroad"
                     desc="Knight Ventures welcomes industry wide founders who are of African descent or affinity and have an incorporated business within Africa or Abroad and willing to have their offerings targeted towards the African market."
                     color="#2E3192"
                     icon={incorporated}
                  />
               </div>

               <div className="col-lg-4 d-flex flex-column align-items-center mb-5">
                  <section className="mb-5">
                     <InfoCard
                        title="Possess a Working Prototype"
                        desc="Founders into our Acceleration program should have a verifiable minimum viable product/solution that seeks monetization and scalability with a clear technology enhanced business model"
                        color="#E27007"
                        icon={prototype}
                     />
                  </section>

                  <section>
                     <InfoCard
                        title="Just an Idea to Incubate"
                        desc="Founders who are at an ideation stage, positioning for our incubator program will be expected to articulate their solution and their desired technology enhancement component for impact particularly towards the African Market."
                        color="#44AB96"
                        icon={incubated}
                     />
                  </section>
               </div>

               <div className="col-lg-4 d-flex flex-column align-items-center last-set-criteria">
                  <section className="mb-5">
                     <InfoCard
                        title="Active Customer Base is an Advantage"
                        desc="Particular interest will be given to accelerator applicants that have established an active customer base and evidenced revenue generation"
                        color="#650A9D"
                        icon={active}
                     />
                  </section>

                  <section>
                     <InfoCard
                        title="Minimum of Two Founders Venture"
                        desc="We actively seek for teams of at least 2 or 3. We will admit purely technical teams, but prefer a mix of either one or two technical co-founders, and a business-focused co-founder with the ability to convey a vision to customers, investors, and employees."
                        color="#00ADEF"
                        icon={minimum}
                     />
                  </section>
               </div>
            </section>
         </TitledSection>

         <TitledSection
            className="partner-section"
            title="Partners"
            subtitle="A global community built on collaboration"
         >
            <p>
               Our network of partners share similar passions of creating value and offer a bouquet
               of offerings that enable founders provide efficient solutions with impact outcomes.
            </p>
            <div className="partners-pad">
               <PartnerSlide />
            </div>
         </TitledSection>

         <TitledSection
            title={<p style={{ color: "#00ADEF", marginBottom: 0 }}>Why Join us</p>}
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  Our mission is to create
                  <br /> value through our program
               </p>
            }
            bg="#02031B"
            titlePosition="right"
            className="section-padding-top"
         >
            <section className="row mt-5">
               {whyJoin.map((w, i) => {
                  return (
                     <div className="col-lg-4 mb-3 px-2 d-flex justify-content-center">
                        <InfoCard bg="#0D0E32" title={w.title} icon={w.icon} desc={w.desc} />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         {/* <TitledSection
            title={<p style={{ color: "#00ADEF", marginBottom: 0 }}>Testimonials</p>}
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  Watch some of our cohorts and learn from their stories
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <Testimony />
         </TitledSection> */}

         {/* <TitledSection
            subtitle={<p style={{ color: "#ffffff", marginBottom: 0 }}>Our Capabilities</p>}
            bg="#02031B"
         >
            <section className="earth-space">
               <div className="row pt-3" style={{ opacity: 1 }}>
                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">200+</p>
                     <p className="capacity-label">Startups</p>
                  </article>

                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">30%</p>
                     <p className="capacity-label">Women Founders</p>
                  </article>

                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">$113m</p>
                     <p className="capacity-label">Fund Raised</p>
                  </article>
               </div>

               <div className="row lower-list-capacity" style={{ opacity: 1 }}>
                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">9500+</p>
                     <p className="capacity-label">Investors</p>
                  </article>

                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">20+</p>
                     <p className="capacity-label">Partners</p>
                  </article>

                  <article className="col-lg-4 mb-3">
                     <p className="capacity-score">60+</p>
                     <p className="capacity-label">Mentors</p>
                  </article>
               </div>
            </section>
         </TitledSection> */}

         {/* <TitledSection
            title="Blog"
            subtitle={
               <>
                  Stay updated with
                  <br /> our latest news
               </>
            }
         >
            <section className="position-blog-btn">
               <Button label="Go to our blog" className="blog-sec-btn" />
            </section>
            <section className="d-flex align-items-center home-blog-list">
               <BlogCard
                  bg={ideasToReality}
                  title="Application"
                  subtitle="How to Get Seed Funding From Investors"
                  day="22"
                  month="Sep"
               />

               <BlogCard
                  bg={startupInvestment}
                  title="Investors"
                  subtitle="Getting started as an investor"
                  day="13"
                  month="Jun"
                  width={280}
               />

               <BlogCard
                  bg={productRoadMap}
                  title="Investors"
                  subtitle="Getting started as an investor"
                  day="13"
                  month="Jun"
                  width={280}
               />
            </section>
         </TitledSection> */}

         {/* <FeatureInvestor /> */}

         <section className="d-flex align-items-center justify-content-between subscribe-news flex-wrap">
            <h2>Subscribe our news letter to stay updated</h2>
            <div className="d-flex align-items-center flex-wrap">
               <TextField placeholder="Your Name" />

               <TextField placeholder="Your Email" type="email" />

               <Button label="Subscribe" />
            </div>
         </section>
      </div>
   );
});
