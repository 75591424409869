import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
   Home,
   Accelerator,
   Startups,
   Investor,
   Mentors,
   Partners,
   About,
   Faq,
   Blog,
   SingleBlog
} from "./pages";

const AppRouter = () => {
   return (
      <Router>
         <Routes>
            <Route path="/users" element={<div>users</div>} />
            <Route path="/" element={<Home />} />
            <Route path="/accelerator" element={<Accelerator />} />
            <Route path="/startups" element={<Startups />} />
            <Route path="/investors" element={<Investor />} />
            <Route path="/mentors" element={<Mentors />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<SingleBlog />} />
         </Routes>
      </Router>
   );
};

export default AppRouter;
