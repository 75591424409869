import React from "react";
import banner from "../../assets/images/investorBanner.svg";
import {
   Banner,
   WithLayout,
   TitledSection,
   ListItemLine,
   InfoCard,
   PersonCard,
   FeatureInvestor,
   GetStartedCard
} from "../../components";
import diverseNetwork from "../../assets/images/diverseNetwork.svg";
import pitching from "../../assets/images/pitchingSes.svg";
import investOpp from "../../assets/images/investorConnect.svg";
import masterclass from "../../assets/images/investorMasterclass.svg";
import mentorOpp from "../../assets/images/mentorOpp.svg";
import sampleFinancial from "../../assets/images/sampleFinancial.png";

export const Investor = WithLayout(() => {
   return (
      <div>
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     The future of <span>investment</span>
                  </h1>
               }
               subText="Connecting investors with the most promising startups to create change around Africa."
            />
         </section>

         <TitledSection title="How It Works" subtitle="We are focused in helping startups grow">
            <section className="d-flex align-items-center mt-5 flex-wrap">
               <ListItemLine num="1" text="Application Submission" line="up" />
               <ListItemLine num="2" text="Application Screening" line="down" color="#E27007" />
               <ListItemLine
                  num="3"
                  text="Shortlisting To Pitch Mentor"
                  line="up"
                  color="#650A9D"
               />
               <ListItemLine num="4" text="Founders Interview" color="#2E3192" />
            </section>

            <section className="d-flex align-items-center w-100 flex-wrap">
               <ListItemLine num="5" text="Co-host Selection" line="down" color="#E27007" />
               <ListItemLine num="6" text="Program Enrollment" line="up" color="#650A9D" />
               <ListItemLine num="7" text="Demo Day" line="down" color="#2E3192" />
               <ListItemLine num="8" text="Investment Closure" />
            </section>
         </TitledSection>

         <TitledSection
            title={<p style={{ color: "#00ADEF", marginBottom: 0 }}>Investor’s Benefits</p>}
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  Our mission is to create value through our program
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <section className="row mt-5">
               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={diverseNetwork}
                        title="Diverse Network"
                        bg="#0D0E32"
                        desc="The network is a powerful set of African inclined investors and mentors who are jointly passionate about supporting African curated solutions. This network cuts across diverse fields of expertise and industries showcasing an enviable richness of intellect and success."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={pitching}
                        title="Pitching Sessions"
                        bg="#0D0E32"
                        desc="The Investor connect platform will showcase matched founders in pitching session allowing investors to ask questions."
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={masterclass}
                        title="Angel Investor Masterclass"
                        bg="#0D0E32"
                        desc="Angel investors particularly newbies will be handheld into the journey of investing and given the appropriate environment to feel comfortable and secure amongst other angel investors. Our classes will drawn on peculiarities of the African entrepreneurial  ecosystem and showcase how proper investment can unlock hidden potentials of high ROI. We promote ethical investing"
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={investOpp}
                        title="Investor Opportunity"
                        bg="#0D0E32"
                        desc="Deal flow of quality investment opportunities will be curated here."
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={mentorOpp}
                        title="Mentorship Opportunity"
                        bg="#0D0E32"
                        desc="Investors have an opportunity to work closely with founders who share their interest and expertise for more hands-on involvement, support and guidance."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={pitching}
                        title="Priority Placement"
                        bg="#0D0E32"
                        desc="High potential business investment opportunities will be availed to ensure ample time to review for decision making."
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2 depressed-list">
                  <article className="mb-3">
                     <InfoCard
                        icon={diverseNetwork}
                        title="Convenience"
                        bg="#0D0E32"
                        desc="All investment opportunities will be made available in the most convenient manner and affording you easy decision making without a clutter of information and time stealing activities."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={masterclass}
                        title="Angels Investor Network"
                        bg="#0D0E32"
                        desc="The worth of the network is undoubtedly of great value towards both personal and professional enrichment."
                     />
                  </article>
               </div>
            </section>
         </TitledSection>

         {/* <TitledSection
            title="Our leading Angel investors"
            subtitle="Meet some of our top financials"
            titlePosition="center"
         >
            <section className="row mt-5">
               {Array.from("four").map((x, i) => {
                  return (
                     <div className="col-lg-3 mb-4" key={`financials-${i}`}>
                        <PersonCard name="Andy Ford" pic={sampleFinancial} />
                     </div>
                  );
               })}
            </section>
         </TitledSection> */}

         {/* <section>
            <FeatureInvestor
               title={
                  <>
                     Our Top Venture
                     <br />
                     Capitalist
                  </>
               }
            />
         </section> */}

         <section className="get-started-space">
            <GetStartedCard text="Join our investor program today!" />
         </section>
      </div>
   );
});
