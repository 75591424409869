import React from "react";
import { Banner, TitledSection, WithLayout, GetStartedCard, PartnerCard } from "../../components";
import banner from "../../assets/images/partnerBanner.png";
import apple from "../../assets/images/Apple.svg";
import nike from "../../assets/images/Nike.svg";
import kandHR from "../../assets/images/KandBHR.jpeg";
import yebox from "../../assets/images/yebox.svg";
import zendesk from "../../assets/images/zendesk.png";
import icit from "../../assets/images/icit.png";
import strategic from "../../assets/images/strategic.jpeg";
import "./partners.css";
export const Partners = WithLayout(() => {
   return (
      <div>
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     <span>Partner</span> with world class startups
                  </h1>
               }
               subText="Connecting investors with the most promising startups to create change around Africa."
            />
         </section>

         <TitledSection title="our Partners" subtitle="A global community built on collaboration">
            <p className="mt-4">
               Our network of partners share similar passions of creating value and offer a bouquet
               of offerings
               <br /> that enable founders provide efficient solutions with impact outcomes.
            </p>

            <section className="mb-5">
               {/* <Tab tabItems={tabs} activeTab={activeTab} className="partner-tab" /> */}

               <div className="row mt-5">
                  <section className="col-lg-3 px-1 mb-2">
                     <PartnerCard
                        icon={zendesk}
                        title="Zendesk"
                        desc="Zendesk is a service-first CRM company that builds software designed to improve customer relationships"
                     />
                  </section>

                  <section className="col-lg-3 px-1 mb-2 d-flex">
                     <PartnerCard
                        icon={kandHR}
                        title="KandB"
                        desc="a human resource management system that aids to manage employee, appraisal systems, payroll management etc."
                     />
                  </section>

                  <section className="col-lg-3 px-1">
                     <PartnerCard
                        icon={yebox}
                        title="Yebox"
                        desc="a digital consultancy and software development company that provides cutting edge engineering  and high-impact business solutions"
                     />
                  </section>

                  <section className="col-lg-3 px-1 mb-2">
                     <PartnerCard
                        icon={icit}
                        title="ICIT"
                        desc="ICIT is an information technology solutions provider with a mission to empower businesses in delivering more results with less resources faster in an automated environment."
                     />
                  </section>

                  {/* <section className="col-lg-3 px-1 mb-2">
                     <PartnerCard
                        icon={strategic}
                        title="Strategic Enterprise -Level solutions"
                        desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id bibendum semper morbi suscipit. Volutpat blandit consequat nulla et et tincidunt dui proin tincidunt. Metus, enim, venenatis amet mauris at ultrices turpis enim hendrerit. Ut in massa turpis et, purus pellentesque est nisl. Diam id blandit gravida varius."
                     />
                  </section> */}
               </div>
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our partner program today!" />
         </section>
      </div>
   );
});
