import React from "react";
import { Button } from "..";
import "./getStartedCard.css";

export const GetStartedCard = ({ text = "", action = () => {} }) => {
   return (
      <div className="started-card flex-wrap">
         <p>{text}</p>
         {/* <Button label="Get Started" onClick={action} /> */}
         <a href="https://knight.ventures/waitlist.html">Get Started</a>
      </div>
   );
};
