import React from "react";
import "./tab.css";

export const Tab = ({ tabType = "block", tabItems = [], activeTab = 0, className = "" }) => {
   if (tabType?.toLowerCase() === "block") {
      return (
         <ul className={`block-tab flex-wrap ${className}`} style={{ rowGap: "1rem" }}>
            {tabItems?.length > 0 &&
               tabItems.map((tab, i) => {
                  return (
                     <li
                        className={`${activeTab === i ? "kv-tab-active" : "kv-tab"}`}
                        role="button"
                        onClick={tab.action}
                        key={`block-tab-${i}`}
                     >
                        {tab.name}
                     </li>
                  );
               })}
         </ul>
      );
   } else {
      return (
         <ul className="dash-tab">
            {tabItems?.length > 0 &&
               tabItems.map((tab, i) => {
                  return (
                     <li
                        className={`${activeTab === i ? "kv-dash-active" : "kv-dash"}`}
                        role="button"
                        onClick={tab.action}
                        key={`dash-tab-${i}`}
                     >
                        {tab.name}
                     </li>
                  );
               })}{" "}
         </ul>
      );
   }
};
