export const data = {
   selectionData: [
      {
         text: "Confirm validity of problem",
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: "Strengthening the idea and solution",
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: "Research market",
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: "Test commercial viability ",
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: "Build MVP",
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: "Building THE Team (Technical & Business)",
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: "Product and market deepening",
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: "Growth strategies",
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: "Get Investment ready",
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: "Pitch preparations",
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: "Team Coordination",
         color: "#00ADEF",
         border: "#095876"
      },
      {
         text: "Visible product traction",
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: "Sustaining Customer acquisition strategies",
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: "Establish revenue traction",
         color: " #44AB96",
         border: "#106654"
      },
      {
         text: "Expansion strategies",
         color: "#2E3192",
         border: "#0C0E68"
      },
      {
         text: "Funding options",
         color: "#00ADEF",
         border: "#095876"
      }
   ],

   curriculum: [
      {
         title: "Ideation (Innovation and Design thinking)",
         desc: "This is the entry level for the journey towards entrepreneurship. It begins with an idea. But the idea should ideally solve a problem or address a gap or need. This is where participants will develop thinking capabilities  and strategies on their Business Model Canvas."
      },
      {
         title: "Product market fit",
         desc: "This is a core aspect of the program that takes the idea and moves it along the way towards a product. Here the business framework is fleshed out and subjected to market reaction to measure if the appetite is strong, sustainable and scalable"
      },
      {
         title: "Setting up and Building the company",
         desc: "Building a business requires a choreography of steps that will enhance your business scalability and investment success. You will be guided through each step from setting up a company to building it"
      },
      {
         title: "The power of asking questions.",
         desc: "Knowledge is power so keep enquiring to get that power. Asking questions is key in business building because the dynamics of the environment keep changing. We will encourage founders to ask questions that aid solution building"
      },
      {
         title: "How to effectively research the market",
         desc: "Before you venture out to create any product or solution, it is wise to first learn and deeply understand the market or industry. Without a detailed understanding of the competitive landscape,industry structure, growth outlook and other key factors, your idea could be fraught with incorrect existential assumptions"
      },
      {
         title: "Promoting your brand to an Audience.",
         desc: "Founders on the accelerator will find a considerable amount of time will be taken up speaking to different sets of audiences either to sell the product, potential partners, employees and of course investors. Your brand is being defined each time you speak. You will learn the requirements for each audience and how to communicate value."
      },
      {
         title: "Building your MVP",
         desc: "Feeling the pulse of the market and target customers must be a tested exercise. This aids your product development and sets the trend for usability, consistent purchase and customer acquisition channels that are favourable. You must be able to demonstrate that your product or service meets this criteria and can command revenue."
      },
      {
         title: "Market Penetration",
         desc: "Initial traction will only get you so far. You now need to deepen your market penetration with your product or solution, it is wise to understand the market or industry trends and promote your value proposition to more defined market targets."
      },
      {
         title: "The Heart of Marketing: Storytelling",
         desc: "Every business must master the art of showcasing their business to the right audience with a solution they are happy to acquire. Their story must be compelling enough to the market to keep them buying. This skill is key to customer acquisition and maintenance."
      },
      {
         title: "Communicating your story",
         desc: "Keeping the attention and interest of your listener whilst articulating your business mission through a compelling story requires training and practice especially when you have limited time. This module enhances your pitch readiness"
      },
      {
         title: "Founder and Co-founder practises",
         desc: "In business there must be alignment of values and clarity of roles. This helps the co-founders put in their best.There are tested practises that assist the business relationship flourish. This sets the tone  and culture for the next stage of team hiring"
      },
      {
         title: "Legal and Intellectual property",
         desc: "Very important to engage legal advisory services to ensure proper business papers and safeguard any intellectual property /solution before raising funds."
      },
      {
         title: "Investor Readiness",
         desc: "Best practises, templates and mindset towards documentation requirements, propositions, valuations and ROI expectations."
      },
      {
         title: "How to raise capital from your network",
         desc: "Your network is your net worth is a saying that will be actively learnt and executed in this program"
      },
      {
         title: "How to fund your startup from MVP to IPO.",
         desc: "The program will guide each founder in learning the balance in timing for funds and the corresponding valuation of their business at different stages/ rounds of fundraising in order to keep the balance of ownership and release of equity. The journey is long so it must be prepared for."
      },
      {
         title: "Resourcing for Scaling up",
         desc: "Higher levels of growth require a new set of resources for operational management and quality quality control and team"
      },
      {
         title: "Measuring success and targets",
         desc: "As your business grows opportunities present themselves. Success here is determining what success means and what opportunities can be taken on. This balance is key for continued scale."
      },
      {
         title: "Deal negotiation",
         desc: "Presentation at meetings and negotiating VC termsheets"
      },
      {
         title: "Funding higher levels of growth",
         desc: "Decipher the proper funding structure and options for your stage of growth."
      },
      {
         title: "Board/ Advisory Board",
         desc: "This stage of growth requires more transparency and corporate governance practises inducted into the business. This program will guide on how to engage suitable persons and the role to be played by the board."
      }
   ],

   sectorItem: [
      {
         text: "Fintech",
         color: "#44AB96"
      },
      {
         text: "Agriculture",
         color: "#32ABE2"
      },
      {
         text: "Blockchain",
         color: "#2E3192"
      },
      {
         text: "Sports",
         color: "#44AB96"
      },
      {
         text: "Education",
         color: "#32ABE2"
      },
      {
         text: "Energy",
         color: "#2E3192"
      },
      {
         text: "Transportation",
         color: "#44AB96"
      },
      {
         text: "Insurance",
         color: "#32ABE2"
      },
      {
         text: "Finance",
         color: "#44AB96"
      },
      {
         text: "Health",
         color: "#44AB96"
      }
   ],

   faq: [
      {
         title: "About Knight Ventures",
         desc: (
            <>
               <p>
                  Knight Ventures is a Pan Africa Digital Accelerator providing a platform to
                  connect start-ups, mentors and Investors. We have a mission to develop 100
                  high-growth tech enabled start-ups to scale and success.
               </p>
               <p>
                  We roadmap the innovative ideas of founders (particularly women) towards solutions
                  that are product-market fit ready and have economic, social, environmental and
                  financial impact to critical stakeholders whilst providing them with tools,
                  resources, mentoring and access to funding. All achieved through collaboration
                  with our African success aligned network of corporate partners, best academy
                  alumni and the investors community.
               </p>
               <p>
                  We select our cohorts through a 3 stage selection process to run a 3months
                  program. We offer curriculum-based mentoring to entrepreneurs during the program
                  and guide them for a Demo Day to pitch in front of our network of local and global
                  Investors.
               </p>
            </>
         )
      },
      {
         title: "When is the Knight Accelerator program?",
         desc: "We have rolling applications throughout the year. Each cohort runs for three months for the Accelerator and six months for the Incubator. Programming and events are optimized for time zones in Lagos & London, though founders may participate from anywhere in the world."
      },
      {
         title: "Where is the Knight Accelerator program?",
         desc: "Founders participate in the accelerator from wherever they choose to work virtually and build their companies. All programming and events are designed for the best possible virtual experience."
      },
      {
         title: "What happens in the Knight Accelerator program?",
         desc: (
            <>
               <p>
                  We offer a structured path to accelerated growth through idea validation,
                  mentorship, traction, fundraising and community for founders and enterprises with
                  ambitious aspirations. While all founders may participate in our structured
                  program and curriculum, much of the experience is personalized to the needs of
                  your company. The program is designed for founders to build their companies in the
                  way that works for them.
               </p>
               <p>
                  The structured program anchors on a curriculum of group conversations, peer
                  gatherings focused on market traction and fundraising, supplemented with focused
                  gatherings on topics founders identify as particularly relevant to them. There are
                  also individualized mentoring sessions with our Knight Partners, successful CEOs,
                  and other experts matched specifically to Founders/Startups for the duration of
                  the program.
               </p>
            </>
         )
      },
      {
         title: "What do Knight founders get from the program?",
         desc: "The Knight Ventures Accelerator program breathes life into a winning business model and offers a structured path to product viability, market entry, customer traction and fundraising in a supportive community and network of enterprise-oriented founders and mentors. "
      },
      {
         title: "What type of company qualifies for the Knight Accelerator?",
         desc: (
            <ul>
               <li>
                  Knight Ventures welcomes industry wide Founders | Enterprises that seek the
                  monetization and scalability of their novel product or solutions ideas through
                  enhanced business model savviness and technology.
               </li>
               <li>
                  We support companies that monetize from enterprises, regardless of business model.
               </li>
               <li>
                  The definition of an "enterprise startup" is based on where the revenue comes
                  from. It's not based on the offering. All product types, customer segments/sizes,
                  and business models are welcome.
               </li>
            </ul>
         )
      },
      {
         title: "How do we evaluate applications to Knight?",
         desc: (
            <>
               <p>
                  The most important element is the team, starting with the technical founder(s).
                  The technical founder must be distinctive, usually with strong technical chops,
                  tenacity, the ability to explain complicated things clearly, judgment in making
                  good tradeoffs, good product sense, and a passion for outcomes, not process.
               </p>
               <p>
                  We prefer teams of at least 2 or 3 and actively encourage women participation or
                  lead. We will admit purely technical teams, but the ideal team has one or two
                  technical co-founders, and one business-side co-founder with the ability to convey
                  a vision to customers, investors, and employees.
               </p>
               <p>We look for founding teams that other talented people will want to join.</p>{" "}
               <p>
                  We also place importance -- though secondary to the team -- on the market. Our
                  focus is on markets that are large enough to warrant the interest of the top
                  venture funds. We do admit stellar teams who do not yet have a clear market focus.
               </p>
            </>
         )
      },
      {
         title: "When is the application due?",
         desc: "Knight Ventures has rolling application deadlines. You may submit an application at any time, and we will review it at the next application date. The sooner you apply, the easier it is to be admitted."
      },
      {
         title: "How does the application process work?",
         desc: "If we invite you to interview, we will do so within two weeks of the deadline. Interviews with Knight’s committee are intense, engaging, and almost always fun! If the committee approves an offer to join, we will extend it to you in the week following the interview."
      },
      {
         title: "How much does it cost?",
         desc: "We are all about your success yet we firmly believe you should invest to attain that success. Our fees are informed by your geography and needs. Our base fee is a subscription fee of $200 a month with a cap of $500 a month for Incubation level founders. "
      }
   ]
};
