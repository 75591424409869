import React from "react";
import "./blogCard.css";

export const BlogCard = ({
   bg = "",
   title = "",
   subtitle = "",
   width = 580,
   day = "",
   month = ""
}) => {
   return (
      <section
         className="blog-card d-flex flex-column justify-content-end"
         style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.502576) 100%), url(${bg})`,
            minWidth: width
         }}
      >
         <div className="date">
            <p className="mb-0 day">{day}</p>
            <p className="mb-0 month">{month}</p>
         </div>
         <p className="title">{title}</p>
         <p className="subtitle">{subtitle}</p>
      </section>
   );
};
