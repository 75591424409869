import React from "react";
import "./partnerCard.css";

export const PartnerCard = ({ icon = "", title = "", desc = "" }) => {
   return (
      <div>
         <section className="partner-card">
            <img src={icon} alt="icon" className="partner-icon" />
            <h5>{title}</h5>
            <p>{desc}</p>
         </section>
      </div>
   );
};
