import React, { useState } from "react";
import { Accordion, Tab, WithLayout } from "../../components/index";
import { data } from "../../data";
import "./faq.css";

export const Faq = WithLayout(() => {
   const { faq } = data;
   const [activeTab, setActiveTab] = useState(0);
   const tabItems = [
      {
         name: "General",
         action: () => {
            setActiveTab(0);
         }
      },
      {
         name: "Mentor",
         action: () => {
            setActiveTab(1);
         }
      },
      {
         name: "Investor",
         action: () => {
            setActiveTab(2);
         }
      },
      {
         name: "Startup",
         action: () => {
            setActiveTab(3);
         }
      }
   ];
   return (
      <div className="faq">
         <section className="text-center mb-5">
            <h1 className="big-subtitle text-white">Frequently Asked Questions</h1>
            {/* <p className="faq-text mb-5">
               What can we assist you with? Knight Ventures offers you the opportunity to save , invest and
               create an investment
            </p> */}

            {/* <div className="d-flex align-items-center search-space">
               <span className="material-icons md-36">search</span>
               <input type="search" />
            </div> */}
         </section>

         {/* <section className="d-flex justify-content-center mb-5 pt-3">
            <Tab tabItems={tabItems} className="faq-tab" activeTab={activeTab} />
         </section> */}

         <section className="mb-5">
            <Accordion items={faq} />
         </section>

         <section className="text-center pt-4">
            <h2 className="big-subtitle text-white">Still Need Help?</h2>
            <p className="faq-text">
               Our team is happy to answer your questions.
               <br /> Contact us and we’ll be in touch as soon as possible.
            </p>
            <div className="d-flex justify-content-center contact-links">
               <a href="mailto:info@knight.ventures">info@knight.ventures</a>
               <div>
                  <a href="tel:+234 1 888 0113">+234 1 888 0113</a>
               </div>
               <a href="tel:+234 913 444 4860">+234 913 444 4860</a>
            </div>

            <p className="mt-4">
               274, Ikorodu Road, Anthony, Lagos State, Nigeria.
               <br /> 4652 Ripley Manor Terr, Olney MD 20832 USA
            </p>
         </section>
      </div>
   );
});
