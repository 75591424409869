import React from "react";
import { useNavigate } from "react-router";
import "./courseCard.css";

export const CourseCard = ({
   title = "",
   author = "",
   date = "",
   tags = [],
   image = "",
   id = 0
}) => {
   const navigate = useNavigate();
   return (
      <div className="course-card" role="button" onClick={() => navigate(`/blog/${id}`)}>
         <section style={{ backgroundImage: `url(${image})` }}>
            <h3>{title}</h3>
            <div>
               {tags.length > 0 &&
                  tags.map((tag, i) => {
                     return (
                        <span style={{ color: i % 2 === 0 ? "#058DC1" : "#1405C1" }}>{tag}</span>
                     );
                  })}
            </div>
         </section>

         <section className="d-flex align-items-center justify-content-between">
            <p style={{ color: "#525151" }}>{author}</p>
            <p style={{ color: "#878181" }}>{date}</p>
         </section>
      </div>
   );
};
