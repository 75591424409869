import React from "react";
import "./button.css";

export const Button = ({ label = "Button", onClick = () => {}, className, ...rest }) => {
   return (
      <button onClick={onClick} className={`kv-btn  ${className ?? ""}`} {...rest}>
         {label}
      </button>
   );
};
