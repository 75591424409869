import React from "react";

export const TitledSection = ({
   bg = "white",
   title = "",
   subtitle = "",
   titlePosition = "left",
   children,
   className = "",
   ...rest
}) => {
   return (
      <section className={`section-wrap ${className}`} style={{ backgroundColor: bg }} {...rest}>
         {title.length !== 0 && (
            <h2 className="small-title" style={{ textAlign: titlePosition }}>
               {title}
            </h2>
         )}
         {subtitle.length !== 0 && (
            <h3 className="big-subtitle" style={{ textAlign: titlePosition }}>
               {subtitle}
            </h3>
         )}
         {children}
      </section>
   );
};
