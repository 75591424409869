import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import "./header.css";

export const Header = () => {
   const [open, setOpen] = useState(false);

   return (
      <div className="header">
         <Link to="/">
            <img src={logo} alt="logo" role="button" />
         </Link>

         <ul className={`desk-header-list ${open ? "open-side" : ""}`}>
            <li>
               <Link to="/accelerator">Accelerator</Link>
            </li>
            <li>
               <Link to="/startups">Startups</Link>
            </li>
            <li>
               <Link to="/investors">Investors</Link>
            </li>
            <li>
               <Link to="/mentors">Mentors</Link>
            </li>
            <li>
               <Link to="/partners">Partners</Link>
            </li>
            <li className={`log-in`} role="button">
               {/* Log In */}
               <a href="https://knight.ventures/waitlist.html" style={{color: "#2e3192",fontWeight: 700}}>Join waitlist</a>
            </li>
         </ul>

         <div className={`hams ${open ? "hams-open" : ""}`} onClick={() => setOpen(!open)}>
            <span></span>
            <span></span>
            <span></span>
         </div>
      </div>
   );
};
