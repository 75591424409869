import React, { useState } from "react";
import {
   BlogCard,
   TitledSection,
   WithLayout,
   Tab,
   CourseCard,
   GetStartedCard
} from "../../components";
import ideasToReality from "../../assets/images/ideasToReality.png";
import startupInvestment from "../../assets/images/startupInvestment.png";
import productRoadMap from "../../assets/images/productRoadmap.png";
import courseBg1 from "../../assets/images/courseBg1.png";
import courseBg2 from "../../assets/images/courseBg2.png";
import "./blog.css";

export const Blog = WithLayout(() => {
   const [activeTab, setActiveTab] = useState(0);
   const coursesTab = [
      {
         name: "Accelerators",
         action: () => {
            setActiveTab(0);
         }
      },
      {
         name: "Startups",
         action: () => {
            setActiveTab(1);
         }
      },
      {
         name: "Investors",
         action: () => {
            setActiveTab(2);
         }
      },
      {
         name: "Mentors",
         action: () => {
            setActiveTab(3);
         }
      },
      {
         name: "Partners",
         action: () => {
            setActiveTab(4);
         }
      }
   ];

   const courseData = [
      { image: courseBg1 },
      { image: courseBg2 },
      { image: courseBg1 },
      { image: courseBg2 },
      { image: courseBg1 },
      { image: courseBg2 }
   ];
   return (
      <div>
         <TitledSection
            title="blog"
            subtitle={
               <>
                  Featured news
                  <br /> handpicked for you
               </>
            }
         >
            <BlogCarousel />
         </TitledSection>

         <TitledSection
            title="explore our collections"
            subtitle={
               <>
                  A poll of world class <br />
                  courses at your finger tip
               </>
            }
         >
            <section className="mt-5 mb-5">
               <Tab tabItems={coursesTab} activeTab={activeTab} className="blog-tab" />
            </section>

            <section className="row">
               {courseData.map((course, i) => {
                  return (
                     <div className="col-lg-4 mb-4">
                        <CourseCard
                           title="Get Started with a Business Plan"
                           author="Sandra James"
                           date="December 8, 2021"
                           tags={["Accounting", "Startups"]}
                           image={course.image}
                           id={i}
                        />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our program today!" />
         </section>
      </div>
   );
});

const BlogCarousel = () => {
   return (
      <div
         id="carouselExampleIndicators"
         className="carousel slide blog-carousel"
         data-ride="carousel"
      >
         <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
         </ol>
         <div className="carousel-inner">
            <div className="carousel-item active">
               {/* <img className="d-block w-100" src="..." alt="First slide" /> */}
               <section className="blog-carousel-content">
                  <section className="d-flex align-items-center home-blog-list">
                     <BlogCard
                        bg={ideasToReality}
                        title="Application"
                        subtitle="How to Get Seed Funding From Investors"
                        day="22"
                        month="Sep"
                     />

                     <BlogCard
                        bg={startupInvestment}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     />

                     <BlogCard
                        bg={productRoadMap}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     />
                  </section>
               </section>
            </div>
            <div className="carousel-item">
               <section className="blog-carousel-content">
                  <section className="d-flex align-items-center home-blog-list">
                     <BlogCard
                        bg={ideasToReality}
                        title="Application"
                        subtitle="How to Get Seed Funding From Investors"
                        day="22"
                        month="Sep"
                     />

                     <BlogCard
                        bg={startupInvestment}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     />

                     <BlogCard
                        bg={productRoadMap}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     />
                  </section>
               </section>
            </div>
            <div className="carousel-item">
               <section className="blog-carousel-content">
                  <section className="d-flex align-items-center home-blog-list">
                     <BlogCard
                        bg={ideasToReality}
                        title="Application"
                        subtitle="How to Get Seed Funding From Investors"
                        day="22"
                        month="Sep"
                     />

                     <BlogCard
                        bg={startupInvestment}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     />

                     <BlogCard
                        bg={productRoadMap}
                        title="Investors"
                        subtitle="Getting started as an investor"
                        day="13"
                        month="Jun"
                        width={280}
                     />
                  </section>
               </section>
            </div>
         </div>
         <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
         >
            <i class="bi bi-chevron-left"></i>

            <span className="sr-only">Previous</span>
         </a>
         <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
         >
            <i class="bi bi-chevron-right"></i>
            <span className="sr-only">Next</span>
         </a>
      </div>
   );
};
