import React from "react";
import "./partnerSlide.css";
import Slider from "react-slick";
import zendesk from "../../assets/images/zendesk.svg";
import icit from "../../assets/images/icit.png";
import yebox from "../../assets/images/yebox.svg";
import kandb from "../../assets/images/KandBHR.jpeg";

export const PartnerSlide = () => {
   var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      nextArrow: (
         <div class="slide-arrow">
            <i class="bi bi-chevron-right"></i>
         </div>
      ),
      prevArrow: (
         <div class="slide-arrow">
            <i class="bi bi-chevron-left"></i>
         </div>
      ),
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };
   return (
      <div className="partner-slide">
         <Slider {...settings}>
            <div>
               <img src={zendesk} alt="Zendesk" />
            </div>

            <div>
               <img src={icit} alt="ICIT" />
            </div>

            <div>
               <img src={yebox} alt="Yebox Technologies" />
            </div>

            <div>
               <img src={kandb} alt="Knight and Bishop HRM" />
            </div>
         </Slider>
      </div>
   );
};
