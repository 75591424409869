import React, { useEffect, useState } from "react";
import "./accordion.css";

export const Accordion = ({ items = [] }) => {
   return (
      <div id="accordion">
         {items.length > 0 &&
            items.map((item, i) => {
               return (
                  <Accord id={i} title={item?.title} desc={item?.desc} key={`accordion-${i}`} />
               );
            })}
      </div>
   );
};

const Accord = ({ id = 0, title = "", desc = "" }) => {
   const [active, setActive] = useState(false);

   useEffect(() => {
      if (id === 0) {
         setActive(true);
      }
   }, [id]);
   return (
      <div className="card border-0 accord-card mb-4">
         <div
            className="card-header d-flex px-0 py-0 align-items-center justify-content-between accord-header"
            id={`heading-${id}`}
            data-toggle="collapse"
            data-target={`#collapse-${id}`}
            aria-expanded="true"
            aria-controls={`collapse-${id}`}
            role="button"
            onClick={() => setActive(!active)}
         >
            <h5 className="mb-0">{title}</h5>
            <div>{active ? <i class="bi bi-dash-lg"></i> : <i class="bi bi-plus-lg"></i>}</div>
         </div>

         <div
            id={`collapse-${id}`}
            class={`collapse ${id === 0 ? "show" : ""}`}
            aria-labelledby={`heading-${id}`}
            data-parent="#accordion"
         >
            <div class="card-body accord-body px-0 pb-0">{desc}</div>
         </div>
      </div>
   );
};
