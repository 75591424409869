import React from "react";
import {
   WithLayout,
   Banner,
   ListItemLine,
   TitledSection,
   RingBullet,
   InfoCard,
   FeatureInvestor,
   GetStartedCard
} from "../../components";
import banner from "../../assets/images/startupsBanner.svg";
import globalDiscovery from "../../assets/images/globalDiscovery.svg";
import accessGlobal from "../../assets/images/accessGlobal.svg";
import increaseSale from "../../assets/images/increaseSale.svg";
import accessFunding from "../../assets/images/accessFunding.svg";
import accessOpp from "../../assets/images/accessOpp.svg";
import apple from "../../assets/images/Apple.svg";
import jeep from "../../assets/images/Jeep.svg";
import google from "../../assets/images/Google+.svg";
import nike from "../../assets/images/Nike.svg";
import tripAdvisor from "../../assets/images/Tripadvisor.svg";
import github from "../../assets/images/Github.svg";
import { data } from "../../data";
import "./startups.css";

export const Startups = WithLayout(() => {
   const { sectorItem } = data;
   return (
      <div>
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     Grow your <br />
                     <span>Startups</span>
                  </h1>
               }
               subText="From aspiring founders to world renowned entrepreneurs. Knight Ventures can help you achieve this."
            />
         </section>

         <TitledSection title="How It Works" subtitle="We are focused in helping startups grow">
            <section className="d-flex align-items-center mt-5 flex-wrap">
               <ListItemLine num="1" text="Submit Application" line="up" />
               <ListItemLine num="2" text="Initial Screening" line="down" color="#E27007" />
               <ListItemLine num="3" text="Founder’s Interview" line="up" color="#650A9D" />
               <ListItemLine num="4" text="Selection Team" color="#2E3192" />
            </section>

            <section className="d-flex align-items-center w-100 flex-wrap">
               <ListItemLine num="5" text="Cohort Onboarding" line="down" color="#E27007" />
               <ListItemLine num="6" text="12 Weeks Program" line="up" color="#650A9D" />
               <ListItemLine num="7" text="Demo Day Pitch" line="down" color="#2E3192" />
               <ListItemLine num="8" text="Investors Connect" />
            </section>
         </TitledSection>

         <TitledSection
            title={<p style={{ color: "#00ADEF", marginBottom: 0 }}>Selection criteria</p>}
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  Our selection criteria is pretty Simple
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <section className="row mt-5">
               <div className="col-lg-6 d-flex px-2">
                  <CriteriaItem
                     title="Startup Incorporated in Africa"
                     desc="Knight Ventures welcomes industry wide founders who are of African descent or affinity with incorporated business within Africa or Abroad and willing to have their offerings targeted towards the African market."
                  />
               </div>

               <div className="col-lg-6 d-flex px-2">
                  <CriteriaItem
                     title="Posses a Working Prototype"
                     desc="Founders into our Acceleration program should have a verifiable minimum viable product/solution that seeks monetization and scalability with a clear technology enhanced business model."
                  />
               </div>

               <div className="col-lg-4 d-flex px-2">
                  <CriteriaItem
                     title="Just an Idea to incubate"
                     desc="Founders who are at an ideation stage, positioning for our incubator program will be expected to articulate their solution and their desired technology enhancement component for impact particularly towards the African Market."
                  />
               </div>

               <div className="col-lg-4 d-flex px-2">
                  <CriteriaItem
                     title="Active customer base"
                     desc="Particular interest will be given to accelerator applicants that have established an active customer base and evidenced revenue generation"
                  />
               </div>

               <div className="col-lg-4 d-flex px-2">
                  <CriteriaItem
                     title="Minimum of two founder ventures"
                     desc="We actively seek for teams of at least 2 or 3. We will admit purely technical teams, but prefer a mix of either one or two technical co-founders, and a business-focused co-founder with the ability to convey a vision to customers, investors, and employees."
                  />
               </div>
            </section>
         </TitledSection>

         <TitledSection
            title={<p style={{ color: "#2E3192", marginBottom: 0 }}>Program Benefits</p>}
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  Our mission is to create value through our program
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <section className="row mt-5">
               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={globalDiscovery}
                        title="Global Discovery"
                        bg="#0D0E32"
                        desc="Ullamcorper odio semper sit proin. Consectetur aliquet pulvinar cursus pulvinar turpis metus augue elit. Quis blandit scelerisque penatibus bibendum arcu."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={accessGlobal}
                        title="Access to Global Markets"
                        bg="#0D0E32"
                        desc="Ullamcorper odio semper sit proin. Consectetur aliquet pulvinar cursus pulvinar turpis metus augue elit. Quis blandit scelerisque penatibus bibendum arcu."
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={increaseSale}
                        title="Increasing Sales"
                        bg="#0D0E32"
                        desc="Ullamcorper odio semper sit proin. Consectetur aliquet pulvinar cursus pulvinar turpis metus augue elit. Quis blandit scelerisque penatibus bibendum arcu."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={accessFunding}
                        title="Access Funding"
                        bg="#0D0E32"
                        desc="Ullamcorper odio semper sit proin. Consectetur aliquet pulvinar cursus pulvinar turpis metus augue elit. Quis blandit scelerisque penatibus bibendum arcu."
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={accessOpp}
                        title="Access to Opportunities"
                        bg="#0D0E32"
                        desc="Ullamcorper odio semper sit proin. Consectetur aliquet pulvinar cursus pulvinar turpis metus augue elit. Quis blandit scelerisque penatibus bibendum arcu."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={accessGlobal}
                        title="Pool of Experts"
                        bg="#0D0E32"
                        desc="Ullamcorper odio semper sit proin. Consectetur aliquet pulvinar cursus pulvinar turpis metus augue elit. Quis blandit scelerisque penatibus bibendum arcu."
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2 d-flex align-items-center">
                  <article className="mb-3">
                     <InfoCard
                        icon={globalDiscovery}
                        title="Digitised Process"
                        bg="#0D0E32"
                        desc="Ullamcorper odio semper sit proin. Consectetur aliquet pulvinar cursus pulvinar turpis metus augue elit. Quis blandit scelerisque penatibus bibendum arcu."
                     />
                  </article>
               </div>
            </section>
         </TitledSection>

         {/* <section>
            <FeatureInvestor />
         </section> */}

         <TitledSection
            title="Our Sectors"
            subtitle="Well crafted sectors just for you"
            bg="#F9F9FB"
         >
            <section className="d-flex align-items-center w-100 flex-wrap mt-5">
               {sectorItem.map((s, i) => {
                  return (
                     <div className="w-100 sector-item-wrap mb-5">
                        <SectorItem text={s.text} color={s.color} />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         {/* <section className="d-flex align-items-center justify-content-between flex-wrap featured-investor">
            <div className="partner-startup-text">
               <p className="mb-0 partner-startup-title">Partners</p>
               <h2 className="mb-0">A global community built on collaboration</h2>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-wrap partner-startup-icons">
               <img src={apple} alt="apple" />
               <img src={jeep} alt="jeep" />
               <img src={google} alt="google+" />
               <img src={nike} alt="nike" />
               <img src={tripAdvisor} alt="ovo" />
               <img src={github} alt="github" />
               <img src={tripAdvisor} alt="ovo" />
               <img src={github} alt="github" />
            </div>
         </section> */}

         <section className="get-started-space">
            <GetStartedCard text="Join our startup program today!" />
         </section>
      </div>
   );
});

const CriteriaItem = ({ title = "", desc = "" }) => {
   return (
      <div className="startup-criteria-card d-flex">
         <RingBullet size={44} color="#44AB96" border="#FFFFFF" />
         <section>
            <h5>{title}</h5>
            <p>{desc}</p>
         </section>
      </div>
   );
};

const SectorItem = ({ color = "#44AB96", text = "" }) => {
   return (
      <div className="sector-item d-flex align-items-center">
         <RingBullet size={44} color={color} />
         <p className="mb-0">{text}</p>
      </div>
   );
};
