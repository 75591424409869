import React from "react";
import {
   Banner,
   TitledSection,
   WithLayout,
   PersonCard,
   InfoCard,
   GetStartedCard
} from "../../components";
import banner from "../../assets/images/mentorBanner.png";
import sampleFin from "../../assets/images/sampleFinMentors.png";
import diverseNetwork from "../../assets/images/diverseNetwork.svg";
import pitching from "../../assets/images/pitchingSes.svg";
import investOpp from "../../assets/images/investorConnect.svg";
import masterclass from "../../assets/images/investorMasterclass.svg";
import mentorOpp from "../../assets/images/mentorOpp.svg";

export const Mentors = WithLayout(() => {
   return (
      <div>
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     Grow your <br />
                     <span>Startups</span>
                  </h1>
               }
               subText="From aspiring founders to world renowned entrepreneurs. Knight Ventures can help you achieve this."
            />
         </section>

         {/* <TitledSection
            title="Our leading mentors"
            subtitle="Meet some of our mentors"
            titlePosition="center"
         >
            <section className="row mt-5">
               {Array.from("fourFour").map((x, i) => {
                  return (
                     <div className="col-lg-3 mb-4" key={`financials-${i}`}>
                        <PersonCard name="Andy Ford" pic={sampleFin} role="Mentor" />
                     </div>
                  );
               })}
            </section>
         </TitledSection> */}

         <TitledSection
            title={<p style={{ color: "#00ADEF", marginBottom: 0 }}>Mentor’s Benefits</p>}
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  Our mission is to create value through our program
               </p>
            }
            bg="#02031B"
            titlePosition="center"
         >
            <section className="row mt-5">
               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={diverseNetwork}
                        title="Industry Experts"
                        bg="#0D0E32"
                        desc="The network is a powerful set of successful Africans from entrepreneurial backgrounds to academics all ready to share, support and galvanise innovative founders towards domination in their markets.  Each Mentor is carefully selected to ensure ability to give quality mentorship and leverage to the accelerated growth of their mentees."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={pitching}
                        title="Mentor Connect"
                        bg="#0D0E32"
                        desc="The platform is a host to many known Africans and encourages interchange of ideas and opportunities amongst the network."
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={masterclass}
                        title="Access to Networks"
                        bg="#0D0E32"
                        desc="Mentors will be able to access other networks based on their membership of the KV Mentor network which further opens opportunities to be explored."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={investOpp}
                        title="Decision Making Skills"
                        bg="#0D0E32"
                        desc="The network sharpens the skills of Mentors as the are acting almost as co-pilots of the business. Their guidance and support services enhances the decision making skills of mentors in real life circumstances."
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2">
                  <article className="mb-3">
                     <InfoCard
                        icon={mentorOpp}
                        title="Access to Top Level News"
                        bg="#0D0E32"
                        desc="The beauty of the network is the ease of information dissemination in shared industry interest. This access to information is a key benefit to both founders and mentors in strategic decision making."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={pitching}
                        title="Readily Available"
                        bg="#0D0E32"
                        desc="Our mentors are available to bounce ideas on that might need a second opinion or validation at a critical period."
                     />
                  </article>
               </div>

               <div className="col-lg-3 px-2 depressed-list">
                  <article className="mb-3">
                     <InfoCard
                        icon={diverseNetwork}
                        title="Corporate Connection"
                        bg="#0D0E32"
                        desc="The mentor platform provides founders access to opportunities particularly when exploring B2B  expansions, partnerships or corporate engagements based on the diverse industries that our Mentors have worked or are working in."
                     />
                  </article>

                  <article className="mb-3">
                     <InfoCard
                        icon={masterclass}
                        title="Global Mentorship"
                        bg="#0D0E32"
                        desc="The richness of the calibre of the Mentors spans the globe and brings available global exposure and connections to Africans in different parts of the world."
                     />
                  </article>
               </div>
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our mentor program today!" />
         </section>
      </div>
   );
});
