import React from "react";
import "./infoCard.css";

export const InfoCard = ({
   icon = "",
   bg = "white",
   color = "white",
   title = "title",
   desc = "description"
}) => {
   return (
      <div className="info-card" style={{ backgroundColor: bg }}>
         <section>
            <img src={icon} alt="icon" />
         </section>
         <section>
            <p className="title" style={{ color: color }}>
               {title}
            </p>
            <p className="desc" style={{ color: bg === "white" ? "#868686" : "white" }}>
               {desc}
            </p>
         </section>
      </div>
   );
};
