import React from "react";
import { WithLayout, Banner, Button, GetStartedCard } from "../../components";
import banner from "../../assets/images/homeBanner.png";
import post1 from "../../assets/images/post1.png";
import post2 from "../../assets/images/post2.png";
import "./singleBlog.css";

export const SingleBlog = WithLayout(() => {
   const tags = ["Accounting", "Startups"];
   return (
      <div>
         <section>
            <Banner
               mainText={
                  <h1>
                     Investing in people Building businesses across <span>Africa</span>
                  </h1>
               }
               background={banner}
               buttonTitle=""
            >
               <div className="w-100 d-flex align-items-center justify-content-between">
                  <section className="d-flex align-items-center blog-info">
                     <p className="mb-0">By Sandra James</p>
                     <p className="mb-0">
                        <i class="bi bi-dot"></i> Feb 22, 2021
                     </p>

                     <p className="mb-0">
                        <i class="bi bi-dot"></i> 7 Mins Read
                     </p>
                  </section>

                  <section className="d-flex align-items-center" style={{ columnGap: 11 }}>
                     {tags.length > 0 &&
                        tags.map((tag, i) => {
                           return (
                              <span
                                 className="blog-tag"
                                 style={{ color: i % 2 === 0 ? "#058DC1" : "#1405C1" }}
                              >
                                 {tag}
                              </span>
                           );
                        })}
                  </section>
               </div>
            </Banner>
         </section>

         <section className="row single-blog-section">
            <div className="col-lg-4">
               <h6 className="content-header">Content</h6>

               <ul className="content-list">
                  <li>
                     <a href="#business">Defining Business Plan</a>
                  </li>
                  <li>
                     <a href="#plan">Why Business Plan Matter</a>
                  </li>
                  <li>
                     <a href="#common">Common Types of Business Plan</a>
                  </li>
               </ul>
            </div>

            <div className="col-lg-8 px-5 blog-details">
               <p>
                  Leverage agile frameworks to provide a robust synopsis for high level overviews.
                  Iterative approaches to corporate strategy foster collaborative thinking to
                  further the overall value proposition. Organically grow the holistic world view of
                  disruptive innovation via workplace diversity and empowerment.Bring to the table
                  win-win survival strategies to ensure proactive domination. At the end of the day,
                  going forward, a new normal that has evolved from generation X is on the runway
                  heading towards a streamlined cloud solution. User generated content in real-time
                  will have multiple touchpoints for offshoring.Leverage agile frameworks to provide
                  a robust synopsis for high level overviews. Iterative approaches to corporate
                  strategy foster collaborative thinking to further the overall value proposition.
                  Organically grow the holistic world view of disruptive innovation via workplace
                  diversity and empowerment.
               </p>

               <p id="plan">
                  Bring to the table win-win survival strategies to ensure proactive domination. At
                  the end of the day, going forward, a new normal that has evolved from generation X
                  is on the runway heading towards a streamlined cloud solution. User generated
                  content in real-time will have multiple touchpoints for offshoring.Leverage agile
                  frameworks to provide a robust synopsis for high level overviews. Iterative
                  approaches to corporate strategy foster collaborative thinking to further the
                  overall value proposition. Organically grow the holistic world view of disruptive
                  innovation via workplace diversity and empowerment. Bring to the table win-win
                  survival strategies to ensure proactive domination. At the end of the day, going
                  forward, a new normal that has evolved from generation X is on the runway heading
                  towards a streamlined cloud solution. User generated content in real-time will
                  have multiple touchpoints for offshoring.
               </p>

               <h4>Business model that fit your company size</h4>
               <p>
                  Leverage agile frameworks to provide a robust synopsis for high level overviews.
                  Iterative approaches to corporate strategy foster collaborative thinking to
                  further the overall value proposition. Organically grow the holistic world view of
                  disruptive innovation via workplace diversity and empowerment.Bring to the table
                  win-win survival strategies to ensure proactive domination.{" "}
               </p>

               <p>
                  When working with a recruited team of contractors as a non-technical lead you
                  must:
               </p>

               <ul id="common">
                  <li>Take on the responsibility of a product manager.</li>
                  <li>
                     Familiarize yourself with basic technical management and work item assignment
                     using- Kanban tool like Jira, Trello, or a Notion Kanban board.
                  </li>
                  <li>Review and maintain accountability for deliverables daily.</li>
                  <li>
                     Maintain a record (in a spreadsheet, if that’s easier) of everything being
                     worked on, responsible parties, and dates.
                  </li>
                  <li>
                     Do not assume that your team members will be with you for the long run. Make
                     sure all decisions and implementation are well documented.
                  </li>

                  <li>
                     Consider hiring a senior full stack engineering contractor to serve as your
                     technical lead and set best practices for everyone else who works on your code
                     base.
                  </li>
               </ul>
            </div>
            <hr />
         </section>

         <section className="single-blog-section ">
            <p className="comment-count mb-4">2 Comments</p>
            <CommentBox
               name="Maria Winner"
               time="Januari 2, 2020 at 10:10 AM"
               comment="what's up with KV"
            />

            <CommentBox
               name="Magdalena Angel"
               time="Januari 2, 2020 at 10:10 AM"
               comment="winner you are right"
            />

            <div>
               <p className="leave-reply-title">Leave a reply</p>
               <p className="leave-reply-subtitle mb-5">Leave your comment below.</p>

               <section className="row">
                  <div className="col-lg-4 mb-4">
                     <input type="text" placeholder="Name" className="w-100 comment-field" />
                  </div>

                  <div className="col-lg-4 mb-4">
                     <input placeholder="Email" type="email" className="w-100 comment-field" />
                  </div>

                  <div className="col-lg-4 mb-4">
                     <input type="text" placeholder="Company" className="w-100 comment-field" />
                  </div>

                  <div className="col-12 mb-4">
                     <label className="d-block comment-label" htmlFor="">
                        Your comment
                     </label>
                     <textarea name="" id="" rows="4" className="w-100 comment-field"></textarea>
                  </div>

                  <div className="col-12">
                     <Button label="Post comment" className="comment-btn" />
                  </div>
               </section>
            </div>
         </section>

         <section className="single-blog-section">
            <h5 className="recent-post-title mb-5">Recent Post</h5>

            <div className="row">
               <section className="col-lg-6">
                  <PostCard title="Top 5 Destination in Japan" text="Previous" image={post1} />
               </section>
               <section className="col-lg-6">
                  <PostCard
                     title="Tips for Work-Life Balance Work From Home"
                     text="Next"
                     image={post2}
                  />
               </section>
            </div>
         </section>

         <section className="get-started-space">
            <GetStartedCard text="Join our program today!" />
         </section>
      </div>
   );
});

const CommentBox = ({ name = "", time = "", comment = "", image = "" }) => {
   return (
      <div className="comment-box">
         {image?.length === 0 ? (
            <span className="image-placeholder"></span>
         ) : (
            <img src={image} alt={name} />
         )}

         <section>
            <p className="name">{name}</p>
            <p className="time">{time}</p>
            <p className="comment">{comment}</p>
         </section>
      </div>
   );
};

const PostCard = ({ title = "", text = "", image = "" }) => {
   return (
      <div className="post-card">
         <p className="text">{text}</p>
         <img src={image} alt={title} />
         <p className="title">{title}</p>
      </div>
   );
};
