import React from "react";
import "./banner.css";
import { Button } from "../index";

export const Banner = ({
   mainText = "",
   background = "",
   subText = "",
   buttonTitle = "Get Started",
   btnAction = () => {},
   height = 600,
   children,
   ...rest
}) => {
   return (
      <div
         className={`row mx-0 ${rest?.className ?? ""} banner`}
         style={{
            background: `${background.length === 0 ? "gray" : `url(${background})`}`,
            minHeight: height
         }}
      >
         <section className="col-xl-7 col-lg-10 d-flex align-items-center">
            <div>
               {mainText.length !== 0 && <div className="banner-main">{mainText}</div>}
               {subText.length > 0 && <p className="banner-sub">{subText}</p>}
               {buttonTitle.length > 0 && <Button label={buttonTitle} onClick={btnAction} />}
            </div>
         </section>

         {React.Children.count(children) > 0 && (
            <section className="col-12 banner-child">{children}</section>
         )}
      </div>
   );
};
