import React, { useEffect, useState } from "react";
import banner from "../../assets/images/acceleratorBanner.png";
import {
   WithLayout,
   Banner,
   TitledSection,
   ProgramCard,
   RingBullet,
   Tab,
   Accordion,
   ListItemLine,
   GetStartedCard
} from "../../components";
import program1 from "../../assets/images/programImg1.png";
import program2 from "../../assets/images/programImg2.png";
import program3 from "../../assets/images/programImg3.png";
import accessFund from "../../assets/images/accessFund.svg";
import accessMentor from "../../assets/images/accessMentor.svg";
import investorConnect from "../../assets/images/investorConnect.svg";
import corporateConnect from "../../assets/images/corporateConnect.svg";
import support from "../../assets/images/supportWhy.svg";
import { data } from "../../data";
import "./accelerator.css";

export const Accelerator = WithLayout(() => {
   const { selectionData, curriculum } = data;

   const [criteriaActive, setCriteriaActive] = useState(0);
   const [curriculumActive, setCurriculumActive] = useState(0);
   const [start, setStart] = useState(0);
   const [stop, setStop] = useState(7);

   const [startCriteria, setStartCriteria] = useState(0);
   const [stopCriteria, setStopCriteria] = useState(5);

   useEffect(() => {
      if (curriculumActive === 0) {
         setStart(0);
         setStop(7);
      } else if (curriculumActive === 1) {
         setStart(7);
         setStop(15);
      } else if (curriculumActive === 2) {
         setStart(7);
         setStop(20);
      }
   }, [curriculumActive]);

   useEffect(() => {
      if (criteriaActive === 0) {
         setStartCriteria(0);
         setStopCriteria(5);
      } else if (criteriaActive === 1) {
         setStartCriteria(5);
         setStopCriteria(10);
      } else if (criteriaActive === 2) {
         setStartCriteria(10);
         setStopCriteria(16);
      }
   }, [criteriaActive]);

   const criteriaTab = [
      {
         name: "Incubation Program",
         action: () => {
            setCriteriaActive(0);
         }
      },
      {
         name: "Seed Acceleration Bootcamp",
         action: () => {
            setCriteriaActive(1);
         }
      },
      {
         name: "Series A, Seed, Pre-Series A",
         action: () => {
            setCriteriaActive(2);
         }
      }
   ];

   const curriculumTab = [
      {
         name: "Pre-Acceleration Bootcamp",
         action: () => {
            setCurriculumActive(0);
         }
      },
      {
         name: "Seed Acceleration Bootcamp",
         action: () => {
            setCurriculumActive(1);
         }
      },
      {
         name: "Series A, Seed, Pre-Series A Investor Connect",
         action: () => {
            setCurriculumActive(2);
         }
      }
   ];

   return (
      <div>
         <section>
            <Banner
               background={banner}
               mainText={
                  <h1>
                     Begin your <span>investment</span> with us.
                  </h1>
               }
               subText="Knight Venture is a digital accelerator and an investment platform that connects African startups with investors."
            />
         </section>

         <TitledSection title="our programs" subtitle="Boost up with our hands-on program">
            <div className="row mt-5">
               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     image={program1}
                     footer="Incubation Program"
                     desc="The incubation program is to guide the budding entrepreneur in doing research on market & industry"
                     fullDesc="The incubation program is to guide the budding entrepreneur in doing research on the market & industry for detailed understanding of the competitive landscape, industry structure, growth outlook, and other key factors. We help in identifying the problem to be solved by validating the business idea. Our program helps in building an investable product, finding product-market fit, product design and prototyping, how to get early customers and grow pitch deck preparation along with mock pitch session to communicate their mission through storytelling. The duration of program is 6-12 months."
                  />
               </section>

               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     image={program2}
                     footer="Acceleration Program"
                     desc="Our Acceleration program is targeted at founders, who desire to scale their businesses and attract investment. "
                     fullDesc="Our Acceleration program is targeted at founders, who desire to scale their businesses and attract investment. The program runs virtually over a 3-month period with programming and events optimised for WAT time zones, though founders may participate from anywhere in the world. We offer a structured curriculum of strategy development and execution for deepened market traction and the required supporting operational and managerial capacity. Founders will be subjected to rigorous reviews through the duality of matched industry mentors and through peer cohort group conversations to test revenue models, scalability and sustainability. Founders businesses will be appraised and supported using due diligence measures towards determining their level of Investment readiness particularly in areas governance, financial health and returns, brand architecture and partnerships."
                  />
               </section>

               <section className="col-lg-4 d-flex justify-content-center mb-4">
                  <ProgramCard
                     image={program3}
                     className="investor-connect-card"
                     footer="Investor Connect
                     Seed, Pre-Series A, Series A"
                     desc="The investor connect is a network of investors with an African affinity"
                     fullDesc="The investor connect is a network of investors with an African affinity from Angels to Capital Ventures who leverage the platform in accessing quality deal flows of due-diligenced high growth enterprises.

                     The funding gaps to be filled are at early stages from pre-seed to Series A. Deal flows will be expertly matched to interest and expertise in a seamless process allowing for more familiarity with options presented. 
                     
                     Critical value from this platform is the strategic partnerships and network of home-grown investors who will derive deepened capacity and more regional investment opportunities in scalable solutions driven by innovative founders
                     "
                  />
               </section>
            </div>
         </TitledSection>

         <TitledSection
            title="Selection criteria"
            subtitle="Our selection criteria is pretty Simple"
            titlePosition="center"
         >
            <section className="d-flex justify-content-center mt-5 mb-5">
               <Tab tabItems={criteriaTab} activeTab={criteriaActive} />
            </section>

            <section className="row d-flex pt-3">
               {selectionData.slice(startCriteria, stopCriteria).map((s, i) => {
                  return (
                     <div className="col-lg-4 mb-3 px-2 d-flex w-100">
                        <Category color={s.color} text={s.text} border={s.border} />
                     </div>
                  );
               })}
            </section>
         </TitledSection>

         <TitledSection
            title={<p style={{ color: "#00ADEF", marginBottom: 0 }}>Our Curriculum</p>}
            subtitle={
               <p style={{ color: "#ffffff", marginBottom: 0 }}>
                  Building businesses using
                  <br /> our world class methods
               </p>
            }
            bg="#02031B"
         >
            <section className="mb-5 mt-5 pt-3">
               <Tab tabItems={curriculumTab} activeTab={curriculumActive} tabType="dash" />
            </section>

            <section className="pt-3">
               <Accordion items={curriculum.slice(start, stop)} />
            </section>
         </TitledSection>

         <TitledSection title="How It Works" subtitle="Our application process is easy to complete">
            <section className="d-flex align-items-center mt-5 flex-wrap">
               <ListItemLine num="1" text="Application Submission" line="up" />
               <ListItemLine num="2" text="Application Screening" line="down" color="#E27007" />
               <ListItemLine
                  num="3"
                  text="Shortlisting To Pitch Mentor"
                  line="up"
                  color="#650A9D"
               />
               <ListItemLine num="4" text="Founders Interview" color="#2E3192" />
            </section>

            <section className="d-flex align-items-center w-100 flex-wrap">
               <ListItemLine num="5" text="Co-host Selection" line="down" color="#E27007" />
               <ListItemLine num="6" text="Program Enrollment" line="up" color="#650A9D" />
               <ListItemLine num="7" text="Demo Day" line="down" color="#2E3192" />
               <ListItemLine num="8" text="Investment Closure" />
            </section>
         </TitledSection>

         <TitledSection
            title="Why Join Us"
            subtitle="Our mission is to create value through our program"
            titlePosition="center"
            className="px-4"
         >
            <section className="row mt-5">
               <div className="col-lg-3">
                  <article className="mb-5">
                     <WhyItem
                        icon={accessFund}
                        title="Access to Global and local mentors"
                        color="#2E319210"
                        desc="You will be matched to a mix of local and global successful mentors who have experience and expertise closely matched to your field of business and are passionate about supporting your journey. We deliberately seek out mentors who understand the peculiarity of the African terrain and were possibly entrepreneurs themselves. A great opportunity to leverage their insights, networks and reputation."
                     />
                  </article>

                  <article className="mb-5">
                     <WhyItem
                        icon={investorConnect}
                        title="Access to network partners"
                        color="#17B3EE10"
                        desc="We are opportuned to have a network of corporate partners who share in our mission to provide value and give you a headstart with a bouquet of resources, tools, software and platforms to accelerate your business idea."
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list">
                  <article className="mb-5">
                     <WhyItem
                        icon={accessMentor}
                        title="Access to founders Community"
                        color="#E2700710"
                        desc="Thrive and be incentivised as you network and get constructive feedback in a structured manner from other peer cohort members and the founder community."
                     />
                  </article>

                  <article className="mb-5">
                     <WhyItem
                        icon={corporateConnect}
                        title="Access to Investors"
                        color="#1880AC10"
                        desc="Our network of investors are matched by interest in your business and provided upfront information to assist in quick decision making. Participants will have to reach a level of interest for the different funding stages of investment. Our investors are selected for their commitment to ethical investment practises and consciousness to build profitable legacies"
                     />
                  </article>
               </div>

               <div className="col-lg-3">
                  <article className="mb-5">
                     <WhyItem
                        icon={investorConnect}
                        title="Legal and compliance assistance"
                        color="#17B3EE10"
                        desc="We provide our cohort members legal and compliance assistance as part of our proactive due diligence measures to ensure smooth discussions at potential partner and investment negotiations."
                     />
                  </article>

                  <article className="mb-5">
                     <WhyItem
                        icon={corporateConnect}
                        title="Brand and PR negotiations"
                        color="#1880AC10"
                        desc="Visibility is key to your business scale aspirations and we will support this through appropriate negotiations for publicity of your brand and offerings "
                     />
                  </article>
               </div>

               <div className="col-lg-3 depressed-list">
                  <article className="mb-5">
                     <WhyItem
                        icon={support}
                        title="Access to entrepreneurial curriculum"
                        color="#650A9D10"
                        desc="The Knight Ventures program offers a curriculum focused on the business realities of scaling a business and housed within a supportive community of enterprise-oriented founders and mentors.  The structured curriculum anchors peer group conversations  focused on product viability, market traction, customer acquisition and fundraising, supplemented with focused brainstorming on topics founders identify as particularly relevant to them."
                     />
                  </article>

                  <article className="mb-5">
                     <WhyItem
                        icon={accessMentor}
                        title="12 weeks remote program"
                        color="#E2700710"
                        desc="Founders participate in the accelerator from wherever they choose to work virtually and build their companies. All programming and events are designed for the best possible virtual experience. Knight’s physical hubs are in Lagos Nigeria. Networking activities and other in-person events will take place on both platforms in those areas."
                     />
                  </article>
               </div>
            </section>
         </TitledSection>

         <section className="get-started-space">
            <GetStartedCard text="Join our accelerator program today!" />
         </section>
      </div>
   );
});

const Category = ({ text = "", color = "#00ADEF", border = "#095876" }) => {
   return (
      <div className="d-flex align-items-center cat-card w-100">
         <RingBullet size={44} color={color} border={border} />
         <p>{text}</p>
      </div>
   );
};

const WhyItem = ({ title = "", desc = "", color = "", icon = "" }) => {
   return (
      <div className="d-flex" style={{ columnGap: "1rem" }}>
         <span className="why-icon" style={{ backgroundColor: color }}>
            <img src={icon} alt="icon" />
         </span>
         <section className="why-detail">
            <h5>{title}</h5>
            <p>{desc}</p>
         </section>
      </div>
   );
};
