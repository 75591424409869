import React from "react";
import up from "../../assets/images/line-up.svg";
import down from "../../assets/images/line-down.svg";
import "./listItemLine.css";

export const ListItemLine = ({ num = "1", color = "#44AB96", line = "", text = "" }) => {
   const renderImg = () => {
      switch (line) {
         case "up":
            return <img src={up} alt="up" />;
         case "down":
            return <img src={down} alt="down" />;
         default:
            return "";
      }
   };
   return (
      <div className={`${line.length > 0 ? "lg-count" : "sm-count"} px-4 mb-5`}>
         <span className="d-flex w-100 mb-3" style={{ overflow: "hidden" }}>
            <p className="list-num" style={{ backgroundColor: `${color}10`, color: `${color}` }}>
               {num}
            </p>
            {renderImg()}
         </span>
         <p className="line-text">{text}</p>
      </div>
   );
};
