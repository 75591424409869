import React from "react";
import "./ringBullet.css";

export const RingBullet = ({ size = 76, color = "#00ADEF", border = "" }) => {
   return (
      <div className="ring-bullet">
         <span style={{ width: size, height: size, minWidth: size, backgroundColor: color }}></span>
         <span style={{ width: size, height: size, minWidth: size, borderColor: border }}></span>
      </div>
   );
};
